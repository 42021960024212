<template>
  <div class="dependents-table">
    <h3 class="mb-2">Child/Dependent</h3>
    <DataTable :value="dependents" class="rd-table" style="width: 100%">
      <template #header>
          <div class="mobile-header">
              <div class="flex" style="width: 100%; height: 100%;">
                  <div class="flex align-items-center justify-content-start mobile-header-label start" >Label</div>
                  <div class="flex align-items-center justify-content-start mobile-header-label end" >Info</div>
              </div>
          </div>
      </template>
      <Column header="Name" field="name" style="width: 40%" >
        <template #body="slotProps">
          <div v-if="slotProps.data.edit">
            <InputText type="text" v-model="slotProps.data.name" style="width: 85%" />
          </div>
          <div v-else>
            {{slotProps.data.name}}
          </div>
        </template>
      </Column>
      <Column header="Date of Birth" field="dob" style="width: 40%">
        <template #body="slotProps">
          <div v-if="slotProps.data.edit">
            <Calendar :showIcon="true" v-model="slotProps.data.dob" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" autocomplete="off" :max-date="today" ref="datepicker" style="width: 85%">
              <span class="material-icons-round calendar-icon grey" @click="triggerDatepicker">today</span>
            </Calendar>
          </div>
          <div v-else>
            {{ formatDate(slotProps.data.dob) }}
          </div>
        </template>
      </Column>
      <Column header="Actions" >
        <template #body="slotProps">
          <div class="flex align-items-center" v-if="!slotProps.data.edit">
            <span class="material-icons-round grey mr-3" @click="editEntry(slotProps.index, slotProps.data)">edit</span>
            <span class="material-icons-round grey mr-3" @click="removeRow(slotProps.index)">delete</span>
            <Button class="clarity-btn clarity-gradient-grey btn-padded" v-if="slotProps.data.included === false" @click="toggleInclusion(slotProps.index)">Include in the calculation</Button>
            <Button class="clarity-btn clarity-gradient-orange btn-padded" v-else @click="toggleInclusion(slotProps.index)"><span class="material-icons-round white mr-3">check_circle</span>Included</Button>
          </div>
          <div v-if="slotProps.data.edit" class="flex ml-2">
            <Button class="clarity-btn clarity-gradient-orange mr-2" @click="saveRow(slotProps.index)">Save</Button>
            <Button class="clarity-btn clarity-gradient-grey round" @click="cancelEdit(slotProps.index)"><span class="material-icons-round">close</span></Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <div class="flex justify-content-center">
          <Button class="clarity-btn clarity-gradient-orange btn-padded" @click="addDependentRow"><span class="material-icons-round white mr-2">add</span>Add Additional Dependent</Button>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import dayjs from "dayjs";

export default {
  name: "DependentsTable",
  emits: ['updateDependents'],
  props: ['data'],
  setup(props, context) {
    const dependents = ref(props.data ?? [])

    const currentlyEditing = ref()
    const editRowIdx = ref()
    const today = dayjs().toDate();
    const datepicker = ref(null);

    watch(dependents.value, newVal => {
      context.emit('updateDependents', newVal)
    })

    const triggerDatepicker = () => {
      datepicker.value.onButtonClick();
    }

    const formatDate = (date) => {
        return dayjs(date).format('DD/MM/YYYY');
    }

    const addDependentRow = () => {
      dependents.value.push({
        name: '',
        dob: '',
        included: false,
        edit: true
      })
      editRowIdx.value = dependents.value.length-1
    }

    const removeRow = (row) => {
      // @TODO: do we need a confirmation modal here?
      dependents.value.splice(row, 1)
    }

    const editEntry = (row, data) => {
      dependents.value[row].edit = true
      editRowIdx.value = row
      currentlyEditing.value = {...data}
    }

    const saveRow = (row) => {
      dependents.value[row].edit = false;
      currentlyEditing.value = {}
    }

    const cancelEdit = (row) => {
      if (dependents.value[row].name === '') {
        removeRow(row)
        editRowIdx.value = null
      } else {
        dependents.value[row] = currentlyEditing.value
        dependents.value[row].edit = false
        currentlyEditing.value = {}
        editRowIdx.value = null
      }
    }

    const toggleInclusion = (idx) => {
      dependents.value[idx].included = !dependents.value[idx].included
    }

    return {
      datepicker,
      dependents,
      today,
      addDependentRow,
      cancelEdit,
      editEntry,
      removeRow,
      saveRow,
      toggleInclusion,
      triggerDatepicker,
      formatDate
    }
  }
}
</script>

<style scoped>
.btn-padded {
  padding: 15px 15px;
  min-width: 200px;
  justify-content: center;
}
.mobile-header {
    width: 100%;
    height: 54px;
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
    border-radius: 27px 27px 0px 0px;
    opacity: 1;
}
.dependents-table :deep(.p-datatable-header) {
    padding: 0px;
}
.mobile-header {
    display: none;
}
@media only screen and (max-width: 960px)  {
    .mobile-header {
        display: block;
        width: 100%;
        height: 54px;
        background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
        border-radius: 27px 27px 0px 0px;
        opacity: 1;
    }
    .dependents-table :deep(.p-datatable-header) {
        padding: 0px;
    }
    .mobile-header-label {
        color: var(--clarity-pure-white); margin-left: 30px;
    }
    .mobile-header-label.start {
        width: 40%;
        margin-left: 30px;
    }
    .mobile-header-label.end {
        width: 60%;
        margin-right: 30px;
    }
    .dependents-table :deep(.p-column-title) {
        color: var(--clarity-blue);
        text-align: left;
        font: normal normal bold 16px/24px Open Sans;
        letter-spacing: 0px;
    }

}
</style>
