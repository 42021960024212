import dayjs from "dayjs";

/**
 * param Object dependents
 * param Object regularSavings
 * param Object lumpSumSavings
 * param Object schoolFees
 * param Number currentSavings
 *
 * returns Array
 *
 * example:
 *
 * const feeCalc = new EducationFeeCalculator(
 *  {dob:"01/01/2019", edit:false, included:true, name:"Isabella Martin Smith" },
 *  {amount: 3000, frequency: "monthly", growth : 5, increase: 3, name: "Cras nec luctus ISA", start: "2023", timeframe: 6}
 *  {"name": "Mauris tempus augue","amount": 5000,"year": "2024"},
 *  {cost: 2000, dependent: "All", edit: false, from: 4, name: "Prep School", to: 10}
 * )
 *
 * returns [{ "year": "2022", "plusnominal": 0, "capitalstart": 3000, "feesdue": 4000 }, ...]
 */
export const educationFeeCalculator = async (dependents, regularSavings, lumpSumSavings, schoolFees, currentSavings, feeGrowth, investmentGrowth) => {

    // regular savings
    const getRegularSavingsProjection = async () => {
        let savingsTimeline = []
        let finalTimeline = {}

        regularSavings.forEach(entry => {
            let fees = {}
            let increase = entry.increase / 100
            let amount = entry.amount

            if (entry.frequency === 'monthly') {
                amount = parseFloat(entry.amount) * 12
            } else if (entry.frequency === 'weekly') {
                amount = parseFloat(entry.amount) * 52
            }

            for (let i = 0; i < entry.timeframe; i++) {
                // increase value to power of years in payment
                const payment = amount * Math.pow((1 + increase), i)
                fees[parseInt(entry.start) + i] = payment.toFixed(2)
            }
            savingsTimeline.push(fees)
        })

        // pull out to a univeral 'addtopayload' function
        savingsTimeline.forEach(schedule => {
            Object.entries(schedule).forEach(entry => {
                if (finalTimeline[entry[0]]) {
                    finalTimeline[entry[0]] = (parseFloat(finalTimeline[entry[0]]) + parseFloat(entry[1]))
                } else {
                    finalTimeline[entry[0]] = parseFloat(entry[1])
                }
            })
        })

        return finalTimeline
    }

    // lump sum savings
    const getLumpSavingsProjection = async () => {
        let calculatedLumps = {}

        lumpSumSavings.forEach(lump => {
            calculatedLumps[lump.year] = lump.amount
        })

        return calculatedLumps
    }

    const calculateAge = (year, dob) => {
        // dayjs implementation
        let today = dayjs(`${year}/09/01`)
        let birthDate = dayjs(dob, "DD/MM/YYYY");
        return today.diff(birthDate, 'years');
    }

    const feesForChild = (child, fees) => {
        const matching = [];
        // find matching fees for the child.
        fees.forEach((entry) => {
            if (entry.dependent === child || entry.dependent === 'All') {
                matching.push(entry)
            }
        })
        return matching;
    }

    // schooling years
    const getSchoolFeesProjection = async () => {
        //let calculatedFees = {}
        //let ageFees = []
        let growth = 1 + feeGrowth/100
        // get min and max ages for school fee schedule
        //let min, max;
        //let yearsArray = []

        const minYear = dayjs().year()
        let maxYear = dayjs().year()

        // get current year
        const current = dayjs().year()

        //calculate min and max year
        dependents.forEach((child) => {
            if (child.included) {
                const startAge = calculateAge(current, child.dob)
                const childFees = feesForChild(child.name, schoolFees)
                let feeMaxAge = 0;
                childFees.forEach((fee) => {
                    if(fee.to > feeMaxAge){
                        feeMaxAge = fee.to
                    }
                })
                let diff = 0
                if(feeMaxAge > startAge){
                    diff = feeMaxAge-startAge
                }
                const childMaxYear = minYear+diff;
                if(childMaxYear > maxYear){
                    maxYear = childMaxYear;
                }
            }
        })

        // now loop from start year to end year building up the calculated fees.
        const newFeeCalc = {}
        for (let i = minYear; i < maxYear; i++) {
            let due = 0;

            //calculate fees due this year.
            dependents.forEach((child) => {
                if (child.included) {
                    const ageThisYear = calculateAge(i, child.dob)
                    const childFees = feesForChild(child.name, schoolFees)
                    childFees.forEach((fee) => {
                        if(ageThisYear >= fee.from && ageThisYear < fee.to){
                            due += (fee.cost*(Math.pow(growth, i-current)));
                        }
                    })
                }
            })

            newFeeCalc[i] = due
        }


        /*
        schoolFees.forEach((entry, idx) => {
            // get min and max ages for school fee schedule
            if (idx === 0) {
                min = entry.from
                max = entry.to
            } else {
                if (entry.from < min) {
                    min = entry.from
                }
                if (entry.to > max) {
                    max = entry.to
                }
            }

            // build object of age related fees for each child allowing for increase of fees
            let tempFee = entry.cost
            ageFees[entry.dependent] = []
            for (let g = entry.from; g <= entry.to; g++) {
                ageFees[entry.dependent].push({[parseInt(g)]: tempFee})
                tempFee = tempFee * growth
            }
        })

        console.log('age fees is ', ageFees);

        // iterate children
        dependents.forEach(child => {
            if (child.included) {
                let targetYear = current
                const startAge = calculateAge(targetYear, child.dob)
                // build an array of years where the is a child between the ages on the min and max values
                for (let i = startAge; i <= max; i++) {
                    if (!yearsArray[targetYear]) {
                        yearsArray.push(targetYear)
                    }
                    targetYear++
                }
            }
        })

        // use this arrays of educational years to determine what fees are due
        yearsArray.forEach(feeYear => {
            let due = 0
            // check for individualised entries
            dependents.forEach(child => {
                if (child.included) {
                    const age = calculateAge(feeYear, child.dob)
                    const hasFeesDue = schoolFees.filter(entry => entry.dependent === child.name && age >= entry.from && age <= entry.to)
                    // if feesDue
                    if (hasFeesDue.length) {
                        // ageFees[child.name]
                        let r = ageFees[child.name].findIndex(y => {
                            // x * 1 => casts string to number/int
                            return Object.keys(y)[0] * 1 === age
                        })
                        due += hasFeesDue.length ? Object.values(ageFees[child.name][r])[0] : 0
                    }

                    if (!hasFeesDue.length) {
                        const feesForAll = schoolFees.filter(entry => entry.dependent === 'All' && age >= entry.from && age <= entry.to)
                        let r
                        if (feesForAll.length) {
                            r = ageFees['All'].findIndex(y => {
                                // x * 1 => casts string to number/int
                                return Object.keys(y)[0] * 1 === age
                            })
                        }
                        due += feesForAll.length ? feesForAll.length * Object.values(ageFees['All'][r])[0] : 0
                    }
                }
            })
            calculatedFees[feeYear] = due
        })

        console.log('calculated fees is ', calculatedFees)
        */

        return newFeeCalc
    }

    // return data for graph
    const getCalculation = async () => {
        const regSavings = await getRegularSavingsProjection()
        const lumpSavings = await getLumpSavingsProjection()
        const schooling = await getSchoolFeesProjection()

        if (regSavings && lumpSavings && schooling) {
            // reset graph
            let fees = []

            //user educational years instead
            let maxYear = 0;
            Object.entries(schooling).forEach(year => {
                maxYear = year[0]*1;
                fees.push({
                    year: year[0],
                    regular: 0,
                    lump: 0,
                    plusnominal: 0,
                    capitalstart: 0,
                    feesdue: 0
                })
            })

            //add one final entry to log the closing balance.
            fees.push({
                year: ''+(maxYear+1),
                regular: 0,
                lump: 0,
                plusnominal: 0,
                capitalstart: 0,
                feesdue: 0
            })

            // set initial nominal
            Object.entries(regSavings).forEach(installment => {
                fees.forEach(feeYear => {
                    if (installment[0] === feeYear.year) {
                        feeYear.plusnominal = installment[1]
                        feeYear.regular = installment[1]
                    }
                })
            })

            // update nominal with lump sums
            Object.entries(lumpSavings).forEach(lump => {
                fees.forEach(feeYear => {
                    if (lump[0] === feeYear.year) {
                        feeYear.plusnominal = feeYear.plusnominal + lump[1]
                        feeYear.lump = lump[1]
                    }
                })
            })

            // add fees to each year
            Object.entries(schooling).forEach(year => {
                fees.forEach(feeYear => {
                    if (year[0] === feeYear.year) {
                        feeYear.feesdue = parseFloat((feeYear.feesdue + year[1]).toFixed(2))
                    }
                })
            })

            // update balance
            let previousBalance = currentSavings
            for (let i = 0; i < fees.length; i++) {
                fees[i].capitalstart = parseFloat(previousBalance.toFixed(2))
                // apply investment growth here.
                previousBalance = ((fees[i].capitalstart+fees[i].lump)*(1+(investmentGrowth/100))) + ((fees[i].regular - fees[i].feesdue)*((1+(investmentGrowth/100)/2)))
            }

            if (fees.length) {
                return fees
            }
        }
    }

    return await getCalculation()
}
