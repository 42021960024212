<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="blue">Education Fees Calculator</h2></template>
        <template #content>
              <span @click="$router.back()" style="cursor: pointer;">
                <span class="material-icons-round" style="vertical-align: middle;">chevron_left</span>Back to Calculators
              </span>
        </template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex flex-column">
        <h3 class="mb-3">Welcome to the Education Fees Calculator</h3>
        <div class="flex align-items-center justify-content-between">
          <div class="mr-5">
            <div class="card-body">Use the Education Fees Calculator to project how much you need to save, over what
              period,
              to cover your child's education costs. Please note that the results are calculated using entered
              assumptions and
              are not guaranteed. Please read the reference below prior to using the calculator. Our research library
              provides
              further information about investing for children.
            </div>
          </div>
        </div>
      </div>

      <spacer-h/>

      <DependentsTable @updateDependents="updateDependents" :data="dependents"/>

      <spacer-h/>

      <TabView ref="educationtab" v-model:activeIndex="active">
        <TabPanel>
          <template #header>
              <h3><span class="hide-column">Estimate</span> Education Costs</h3>
          </template>
          <div>
            <h3>Estimate education costs</h3>
            <div class="flex flex-column">
              <div class="flex align-items-center py-2">
                <label class="title bold mr-2">Fees assumed to rise by (per annum):</label>
                <span class="material-icons-round grey"
                      v-tooltip.top="'Assumed 2.5% growth. Please insert own figure if appropriate.'">info</span>
              </div>
              <InputNumber v-model="rate" suffix=" %" :min="0.0" :maxFractionDigits="2" :step="0.1" style="width: 25%"
                           show-buttons
                           incrementButtonClass="investmentCalculatorInputBtnUp"
                           decrementButtonClass="investmentCalculatorInputBtnDown"/>
            </div>

            <spacer-h/>

            <SchoolFeesTable :dependentsOptions="dependents" @updateSchooling="updateSchoolEntries"
                             :schooling="schoolEntries"/>

            <spacer-h/>

          </div>
        </TabPanel>
        <TabPanel>
          <template #header>
            <h3><span class="hide-column">Current/Proposed</span> Savings</h3>
          </template>
          <div>
            <h3 class="mt-2">Current/Proposed Savings</h3>
            <h4 class="title grey">Exising Savings</h4>
            <div class="flex">

              <div class="flex flex-column" style="width: 50%">
                <label class="bold title">Current Value:</label>
                <InputNumber name="currentvalue" v-model="currentValue" prefix="£ "/>
              </div>

              <div class="flex flex-column ml-6" style="width: 50%">
                <label class="bold title">Growth Rate On Savings:</label>
                <InputNumber name="growthrate" v-model="growthRate" suffix="%"/>
              </div>
            </div>


            <spacer-h/>

            <SavingsTable @updateRegular="updateRegularSavings" :regularSavings="regular"/>

            <spacer-h/>

            <LumpSumTable @updateLump="updateLumpSavings" :lumpSavings="lumps"/>

            <spacer-h/>

          </div>
        </TabPanel>
      </TabView>

      <spacer-h/>

      <CurrentSavingsLevel :savings="savingsState"/>

      <spacer-h/>

      <EducationFeeProjectionChart :feesData="fees" :bar-width="40" v-if="display"/>

      <div class="flex flex-column mt-6">
        <h3 class="mb-3">REFERENCE</h3>
        <h4 class="my-2 blue">DISCLAIMER</h4>
        <p class="small-text mb-3">This calculator is to be considered as a guide only and makes assumptions which need to be referred to. Specifically, any assumed growth rates and investment returns may not be achieved. Applicable tax rates and relevant legislation are correct as at February 2023 but may be subject to change. It does not represent personalised advice.</p>
        <h4 class="my-2 blue">CALCULATIONS</h4>
        <ul>
          <li>Monthly payments are assumed to commence at the start of the next school year (in relation to today's
            date).
            If you are able to start payments prior to this or are already making payments, please enter the value of
            these
            savings in the existing funds box.
          </li>
          <li>Interest is accrued per payment period and then applied annually. Annual premiums will receive a full
            year’s
            growth in the year of investment and this will be applied to the balance at the end of the year.
            Monthly/Weekly premiums will accrue interest from the date of investment so the first payment will receive a
            year’s growth, the second payment 11/12 and so on.
          </li>
          <li>Savings increases are applied annually so with a monthly saving of £100 increasing by 6% per annum
            the calculations assume 12 payments of £100 in the first year and then twelve payments of £106 in the second
            year.
          </li>
        </ul>
        <h4 class="my-2 blue">THE GROWTH RATES</h4>
        <p class="small-text">The FCA growth rates, before charges are taken into account, are:</p>
        <ul>
          <li>For untaxed products (for example, pensions and ISAs): low return is 2%, medium is 5% and a high return is 8%.
          </li>
          <li>For taxed products (for example, collective investment schemes and mortgage endowments): low return is 1.5%, medium is 4.5% and a high return is 7.5%.
          </li>
        </ul>
        <h4 class="my-2 blue">OTHER RESOURCES</h4>
        <div id="research-note-links" class="flex flex-wrap">
            <div class="flex calc-note align-items-start">
                <a :href="clarityURL+'//sites/default/files/research/notes/unit_trusts.pdf'"
                                                             target="_blank"><img style="vertical-align:middle;" src="@/assets/icons/pdf-icon.svg">Unit Trusts,
                OEICs and ICVCs</a>
            </div>
            <div class="flex calc-note align-items-start">
                <a :href="clarityURL+'//sites/default/files/research/notes/investmentcompanies.pdf'"
                   target="_blank"><img class="ml-3" style="vertical-align:middle;" src="@/assets/icons/pdf-icon.svg">Investment
                    Companies</a>
            </div>
            <div class="flex calc-note align-items-start">
                <a :href="clarityURL+'//sites/default/files/research/notes/quickguide_isa.pdf'"
                   target="_blank"><img class="ml-3" style="vertical-align:middle;" src="@/assets/icons/pdf-icon.svg">ISAs
                    and PEPs</a>
            </div>
        </div>
      </div>

    </template>
  </content-card-full>
</template>


<script>
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import DependentsTable from "@/components/calculators/education/DependentsTable";
import EducationFeeProjectionChart from "@/components/charts/EducationFeeProjectionChart";

import {computed, onMounted, ref, watch} from 'vue'
import SchoolFeesTable from "@/components/calculators/education/SchoolFeesTable";
import SavingsTable from "@/components/calculators/education/SavingsTable";
import LumpSumTable from "@/components/calculators/education/LumpSumTable";
import CurrentSavingsLevel from "@/components/calculators/education/CurrentSavingsLevel";


import {educationFeeCalculator} from "@/core/services/EducationFeeCalculator";

export default {
  name: "EducationCalculator",
  components: {
    CurrentSavingsLevel,
    LumpSumTable,
    SavingsTable,
    SchoolFeesTable,
    EducationFeeProjectionChart,
    ContentCardFull,
    CardTitle,
    DependentsTable,
    SpacerH
  },
  setup() {
    const clarityURL = process.env.VUE_APP_CLARITYURL;
    const display = ref(false)
    const currentValue = ref(0)
    const growthRate = ref(2.50)
    const rate = ref(2.5)
    const schoolEntries = ref([
        {
            name: 'Primary',
            dependent: 'All',
            from: 5,
            to: 11,
            cost: 2000.00,
            edit: false
        }
    ])
    const dependents = ref([
        {
            name: 'First Child',
            dob: '01/01/2020',
            included: true,
            edit: false
        }
    ])
    const regular = ref([])
    const lumps = ref([])

    const fees = ref([])

    onMounted(() => calculateProjection())

    watch(currentValue, () => {
      calculateProjection()
    })
    watch(regular.value, () => {
      calculateProjection()
    })
    watch(lumps.value, () => {
      calculateProjection()
    })
    watch(schoolEntries.value, () => {
      calculateProjection()
    })
    watch(dependents.value, () => {
      calculateProjection()
    })

    const updateDependents = (updated) => {
      dependents.value = updated
      calculateProjection()
    }

    const updateSchoolEntries = (updated) => {
      schoolEntries.value = updated
      calculateProjection()
    }

    const updateRegularSavings = (regular) => {
      regular.value = regular
      calculateProjection()
    }

    const updateLumpSavings = async (lump) => {
      lump.value = lump
      calculateProjection()
    }

    const calculateProjection = async () => {
      let chart = await educationFeeCalculator(dependents.value, regular.value, lumps.value, schoolEntries.value, currentValue.value, rate.value, growthRate.value)

      if (chart) {
        fees.value = chart
        display.value = true
      }
    }

    const savingsState = computed(() => {
      if (fees.value && fees.value.length) {
        return fees.value[fees.value.length - 1].capitalstart
      }

      return 0.00
    })

    return {
      active: 0,
      currentValue,
      growthRate,
      rate,
      fees,
      lumps,
      regular,
      schoolEntries,
      updateDependents,
      updateSchoolEntries,
      updateRegularSavings,
      updateLumpSavings,
      dependents,
      display,
      savingsState,
      clarityURL
    }
  }
}
</script>

<style scoped>
#research-note-links a {
  color: var(--clarity-dark-grey);
}

.p-tabview :deep(.p-tabview-nav) {
  background: unset;
  border: none;
}

.p-tabview :deep(.p-tabview-nav-container) {
  margin: 0px 30px;
}

.p-tabview :deep(.p-tabview-panels) {
  padding: unset;
  background: var(--clarity-snow-grey);
}

.p-tabview :deep(.p-tabview-nav li .p-tabview-nav-link) {
  background: var(--clarity-snow-grey);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 75px;
  min-width: 280px;
  color: var(--clarity-dark-grey);
  padding-right: 45px;
  padding-left: 45px;
}

.p-tabview :deep(.p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  background: var(--clarity-pure-white);
  color: var(--clarity-blue);
  border: 1px solid var(--clarity-light-grey);
  box-shadow: 0px 5px 20px #0000000D;
  border-radius: 27px 27px 0px 0px;
}

.p-tabview :deep(.p-tabview-ink-bar) {
  background-color: var(--clarity-blue);
  height: 5px;
  border-radius: 4px 4px 0px 0px;
  padding-right: 45px;
  padding-left: 45px;
}

.p-tabview :deep(.p-tabview-panels) {
  /* UI Properties */
  background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--clarity-light-grey);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DCE1E6;
  border-radius: 27px;
  opacity: 1;
  padding: 30px;
}

/*INVESTMENT CALCULATOR INPUT FORMAT CLASSES*/
.investmentCalculatorInputBtnUp {
  width: 50px !important;
  background: var(--clarity-pure-white) !important;
  color: var(--clarity-grey) !important;
  border: 1px solid #dce1e6 !important;
  border-left: 0px !important;
  border-bottom: 0px !important;
}

.investmentCalculatorInputBtnDown {
  width: 50px !important;
  background: var(--clarity-pure-white) !important;
  color: var(--clarity-grey) !important;
  border: 1px solid #dce1e6 !important;
  border-left: 0px !important;
  border-top: 0px !important;
}

.title {
  font-size: 18px;
  padding: 15px 0px;
}

:deep(.p-inputnumber-buttons-stacked .p-inputtext.p-inputnumber-input) {
  border-right: unset !important;
}
.calc-note {
    margin-right: 15px;
    margin-bottom: 15px;
}

:deep(.p-datatable .p-datatable-header) {
    border: 0px;
}

</style>
