<template>
<div class="lump-sum-table">
  <h4 class="grey">Lump sum</h4>
  <DataTable :value="lumpSums" class="rd-table">
    <template #header>
        <div class="mobile-header">
            <div class="flex" style="width: 100%; height: 100%;">
                <div class="flex align-items-center justify-content-start mobile-header-label start" >Label</div>
                <div class="flex align-items-center justify-content-start mobile-header-label end" >Info</div>
            </div>
        </div>
    </template>
    <Column header="Name" field="name" >
      <template #body="slotProps">
        <InputText class="blue-label" type="text" :value="slotProps.data.name" />
      </template>
    </Column>
    <Column header="Amount" field="amount" >
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.amount" prefix="£" :minFractionDigits="2" :maxFractionDigits="2" />
      </template>
    </Column>
    <Column header="Investment Year" field="year" >
      <template #body="slotProps">
        <Dropdown :options="startOptions" v-model="slotProps.data.year" />
      </template>
    </Column>
    <Column header="Action" style="width: 80px;">
      <template #body="slotProps">
        <span class="material-icons-round grey mr-3" @click="removeRow(slotProps.index)">delete</span>
      </template>
    </Column>
    <template #footer>
      <div class="flex justify-content-center">
        <Button class="clarity-btn clarity-gradient-orange" @click="addRow"><span
            class="material-icons-round white mr-2">add</span>Add Lump Sum Saving
        </Button>
      </div>
    </template>
  </DataTable>
</div>
</template>

<script>
import {ref, watch} from "vue";
import dayjs from "dayjs";

export default {
  name: "LumpSumTable",
  emits: ['updateLump'],
  props: ['lumpSavings'],
  setup(props, context) {
    const lumpSums = ref(props.lumpSavings ?? [])

    const startYear = dayjs().year();
    const options = [];
    for (let i = startYear; i < startYear+30; i++) {
      options.push(i.toString());
    }
    const startOptions = ref(options)

    const addRow = () => {
      lumpSums.value.push({
        name: '',
        amount: 0.00,
        year: ''
      })
    }

    const removeRow = (row) => {
      // @TODO: do we need a confirmation modal here?
      lumpSums.value.splice(row, 1)
    }

    watch(lumpSums.value, updatedLump => {
      context.emit('updateLump', updatedLump)
    })

    return {
      lumpSums,
      addRow,
      removeRow,
      startOptions
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.p-inputnumber-buttons-stacked .p-inputtext.p-inputnumber-input) {
  border-right: unset !important;
}
:deep(.p-inputtext){
  width: 100%;
  &.blue-label {
    color: var(--clarity-blue);
    font-weight: bold;
    font-size: 18px;
    padding-left: 20px;
  }
}
:deep(.p-inputwrapper) {
  width: 100%;
}

.mobile-header {
  width: 100%;
  height: 54px;
  background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
  border-radius: 27px 27px 0px 0px;
  opacity: 1;
}
.lump-sum-table :deep(.p-datatable-header) {
  padding: 0px;
}
.mobile-header {
  display: none;
}

@media only screen and (max-width: 960px)  {
  .mobile-header {
    display: block;
    width: 100%;
    height: 54px;
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
    border-radius: 27px 27px 0px 0px;
    opacity: 1;
  }
  .lump-sum-table :deep(.p-datatable-header) {
    padding: 0px;
  }
  .mobile-header-label {
    color: var(--clarity-pure-white); margin-left: 30px;
  }
  .mobile-header-label.start {
    width: 40%;
    margin-left: 30px;
  }
  .mobile-header-label.end {
    width: 60%;
    margin-right: 30px;
  }
  .lump-sum-table :deep(.p-column-title) {
    color: var(--clarity-blue);
    text-align: left;
    font: normal normal bold 16px/24px Open Sans;
    letter-spacing: 0px;
  }
  :deep(.p-inputtext) {
    width: 60%;
  }
  :deep(.p-inputwrapper) {
    width: 60%;
  }

}
</style>
