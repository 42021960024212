<template>
  <DataTable :value="schoolEntries" class="rd-table school-fees-table">
    <template #header>
        <div class="mobile-header">
            <div class="flex" style="width: 100%; height: 100%;">
                <div class="flex align-items-center justify-content-start mobile-header-label start" >Label</div>
                <div class="flex align-items-center justify-content-start mobile-header-label end" >Info</div>
            </div>
        </div>
    </template>
    <Column header="Name" field="name">
      <template #header>
          Name <span class="hide-column-title">& Child/Dependent</span>
      </template>
      <template #body="slotProps">
        <InputText class="blue-label" type="text" :value="slotProps.data.name"
                   placeholder="e.g. Primary, Secondary, Uni"/>
        <div class="hide-column-title pad-top">
          <Dropdown :options="dependentOptions" v-model="slotProps.data.dependent" optionLabel="name"
                  optionValue="name" placeholder="Please select..."/>
        </div>
      </template>
    </Column>
    <Column header="Child/Dependent" field="dependent" class="hide-column">
      <template #header>
          Child/Dependent
      </template>
      <template #body="slotProps">
        <Dropdown :options="dependentOptions" v-model="slotProps.data.dependent" optionLabel="name"
                  optionValue="name" placeholder="Please select..."/>
      </template>
    </Column>
    <Column header="From" field="from" class="max-width" style="width: 180px;">
      <template #header>
          From <span class="hide-column-title">&nbsp;& To</span> (Age)
      </template>
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.from" :min="4" :max="30" :maxFractionDigits="2" :step="1"
                     show-buttons
                     incrementButtonClass="investmentCalculatorInputBtnUp"
                     decrementButtonClass="investmentCalculatorInputBtnDown"/>
        <div class="hide-column-title pad-top">
          <InputNumber v-model="slotProps.data.to" :min="4" :max="30" :maxFractionDigits="2" :step="1"
                     show-buttons
                     incrementButtonClass="investmentCalculatorInputBtnUp"
                     decrementButtonClass="investmentCalculatorInputBtnDown"
                     />
        </div>
      </template>
    </Column>
    <Column header="To (Age)" field="to" class="hide-column" style="width: 180px;">
      <template #header>
          To (Age)
      </template>
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.to" :min="4" :max="30" :maxFractionDigits="2" :step="1"
                     show-buttons
                     incrementButtonClass="investmentCalculatorInputBtnUp"
                     decrementButtonClass="investmentCalculatorInputBtnDown"/>
      </template>
    </Column>
    <Column header="Cost Per Annum" field="cost">
      <template #header>
        Cost Per Annum
      </template>
      <template #body="slotProps">
        <InputNumber v-model="slotProps.data.cost" prefix="£ " :minFractionDigits="2" :maxFractionDigits="2"/>
      </template>
    </Column>
    <Column header="Action" style="width: 80px;">
      <template #header>
          Action
      </template>
      <template #body="slotProps">
        <span class="material-icons-round grey mr-3" @click="removeRow(slotProps.index)">delete</span>
      </template>
    </Column>
    <template #footer>
      <div class="flex justify-content-center">
        <Button class="clarity-btn clarity-gradient-orange btn-padded" @click="addSchoolRow"><span
            class="material-icons-round white mr-2">add</span>Add School Fees
        </Button>
      </div>
    </template>
  </DataTable>
</template>

<script>
import {ref, watch} from 'vue'

export default {
  name: "SchoolFeesTable",
  props: ['dependentsOptions', 'schooling'],
  emits: ['updateSchooling'],
  setup(props, context) {
    const dependents = ref(props.dependentsOptions ?? [])
    const schoolEntries = ref(props.schooling ?? [])

    watch(dependents.value, value => {
      console.log('value is ', value);
      dependentOptions.value = [
        {name: 'All'},
        ...dependents.value
      ]
    })

    const addSchoolRow = () => {
      schoolEntries.value.push({
        name: '',
        from: 0,
        to: 0,
        cost: 0.00,
        edit: false
      })
    }

    const removeRow = (row) => {
      // @TODO: do we need a confirmation modal here?
      schoolEntries.value.splice(row, 1)
    }

    const dependentOptions = ref([
      {name: 'All'},
      ...dependents.value
    ])

    watch(schoolEntries.value, updated => {
      context.emit('updateSchooling', updated)
    })

    return {
      addSchoolRow,
      removeRow,
      schoolEntries,
      dependentOptions,
      dependents
    }
  }
}
</script>

<style scoped lang="scss">
/*INVESTMENT CALCULATOR INPUT FORMAT CLASSES*/
.investmentCalculatorInputBtnUp {
  width: 50px !important;
  background: var(--clarity-pure-white) !important;
  color: var(--clarity-grey) !important;
  border: 1px solid #dce1e6 !important;
  border-left: 0px !important;
  border-bottom: 0px !important;
}

.investmentCalculatorInputBtnDown {
  width: 50px !important;
  background: var(--clarity-pure-white) !important;
  color: var(--clarity-grey) !important;
  border: 1px solid #dce1e6 !important;
  border-left: 0px !important;
  border-top: 0px !important;
}

:deep(.p-inputnumber-buttons-stacked .p-inputtext.p-inputnumber-input) {
  border-right: unset !important;
}
:deep(.p-inputtext){
  width: 100%;
  &.blue-label {
    color: var(--clarity-blue);
    font-weight: bold;
    font-size: 18px;
    padding-left: 20px;
  }
}

:deep(.p-inputwrapper) {
  width: 100%;
}
.btn-padded {
  padding: 15px 45px;
  min-width: 300px;
  justify-content: center;
}
.pad-top {
  padding-top: 5px;
}

@media only screen and (max-width: 960px) {
  :deep(.hide-column-title) {
    display: none;
  }
}

.mobile-header {
  width: 100%;
  height: 54px;
  background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
  border-radius: 27px 27px 0px 0px;
  opacity: 1;
}
.lump-sum-table :deep(.p-datatable-header) {
  padding: 0px;
}
.mobile-header {
  display: none;
}

:deep(.p-column-title) {
  display: none;
}

@media only screen and (max-width: 960px)  {
  .mobile-header {
    display: block;
    width: 100%;
    height: 54px;
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
    border-radius: 27px 27px 0px 0px;
    opacity: 1;
  }
  .school-fees-table :deep(.p-datatable-header) {
    padding: 0px;
  }
  .mobile-header-label {
    color: var(--clarity-pure-white); margin-left: 30px;
  }
  .mobile-header-label.start {
    width: 40%;
    margin-left: 30px;
  }
  .mobile-header-label.end {
    width: 60%;
    margin-right: 30px;
  }
  .school-fees-table :deep(.p-column-title) {
    display: block;
    color: var(--clarity-blue);
    text-align: left;
    font: normal normal bold 16px/24px Open Sans;
    letter-spacing: 0px;
  }
  :deep(.p-inputtext) {
    width: 60%;
  }
  :deep(.p-inputwrapper) {
    width: 60%;
  }

}

</style>
