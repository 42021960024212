<template>
  <div class="savings-table">
    <h4 class="grey">Regular savings</h4>
    <DataTable :value="savingsData" class="rd-table">
      <template #header>
          <div class="mobile-header">
              <div class="flex" style="width: 100%; height: 100%;">
                  <div class="flex align-items-center justify-content-start mobile-header-label start" >Label</div>
                  <div class="flex align-items-center justify-content-start mobile-header-label end" >Info</div>
              </div>
          </div>
      </template>
      <Column header="Name" field="name">
        <template #header>
            <div class="flex-grow-1 text-center">Name <span class="hide-column-title-sm">& Amount</span></div>
        </template>
        <template #body="slotProps">
            <InputText class="blue-label" type="text" :value="slotProps.data.name" />
            <div class="hide-column-title-sm pad-top">
                <InputNumber v-model="slotProps.data.amount" prefix="£ " :minFractionDigits="2" :maxFractionDigits="2" />
            </div>
        </template>
      </Column>
      <Column header="Amount" field="amount" class="hide-column-sm">
        <template #header>
            Amount
        </template>
        <template #body="slotProps">
          <InputNumber v-model="slotProps.data.amount" prefix="£ " :minFractionDigits="2" :maxFractionDigits="2" />
        </template>
      </Column>
      <Column header="Frequency" field="frequency">
        <template #header>
            <div class="flex-grow-1 text-center">Frequency <span class="hide-column-title-sm">& Start Payments</span></div>
        </template>
        <template #body="slotProps">
          <Dropdown :options="freqOptions" v-model="slotProps.data.frequency" optionLabel="label" optionValue="value" />
          <div class="hide-column-title-sm pad-top">
              <Dropdown :options="startOptions" v-model="slotProps.data.start" />
          </div>
        </template>
      </Column>
      <Column header="Start Payments" field="start" class="hide-column-sm">
        <template #header>
            Start Payments
        </template>
        <template #body="slotProps">
          <Dropdown :options="startOptions" v-model="slotProps.data.start" />
        </template>
      </Column>
      <Column header="Timeframe" field="timeframe">
        <template #header>
            <div class="flex-grow-1 text-center">Timeframe <span class="hide-column-title-sm">& Increase</span></div>
        </template>
        <template #body="slotProps">
          <InputNumber v-model="slotProps.data.timeframe" suffix=" years" :step="1" show-buttons
                       incrementButtonClass="investmentCalculatorInputBtnUp"
                       decrementButtonClass="investmentCalculatorInputBtnDown"/>
          <div class="hide-column-title-sm pad-top">
              <InputNumber v-model="slotProps.data.increase" :maxFractionDigits="1" :minFractionDigits="1" :step="0.1" suffix="%"
                           show-buttons
                           incrementButtonClass="investmentCalculatorInputBtnUp"
                           decrementButtonClass="investmentCalculatorInputBtnDown"/>
          </div>
        </template>
      </Column>
      <Column header="Increase" field="increase" class="hide-column-sm">
        <template #header>
            Increase
        </template>
        <template #body="slotProps">
          <InputNumber v-model="slotProps.data.increase" :maxFractionDigits="1" :minFractionDigits="1" :step="0.1" suffix="%"
                       show-buttons
                       incrementButtonClass="investmentCalculatorInputBtnUp"
                       decrementButtonClass="investmentCalculatorInputBtnDown"/>
        </template>
      </Column>
      <Column header="Action" style="width: 80px;">
        <template #header>
            Action
        </template>
        <template #body="slotProps">
          <span class="material-icons-round grey mr-3" @click="removeRow(slotProps.index)">delete</span>
        </template>
      </Column>
      <template #footer>
        <div class="flex justify-content-center">
          <Button class="clarity-btn clarity-gradient-orange" @click="addRow"><span
              class="material-icons-round white mr-2">add</span>Add Regular Saving
          </Button>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import {ref, watch} from 'vue'

export default {
  name: "SavingsTable",
  emits: ['updateRegular'],
  props: ['regularSavings'],
  setup(props, context) {
    const savingsData = ref(props.regularSavings ?? [])

    const freqOptions = ref([
      {
        label: 'Annual',
        value: 'annual',
      },
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      }
    ])

    const startOptions = ref([
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2029',
      '2030',
      '2031',
      '2032',
      '2033',
      '2034',
      '2035',
    ])

    const addRow = () => {
      savingsData.value.push({
        name: '',
        amount: 0.00,
        frequency: '',
        start: '',
        timeframe: 0,
        increase: 0.0
      })
    }

    const removeRow = (row) => {
      // @TODO: do we need a confirmation modal here?
      savingsData.value.splice(row, 1)
    }

    watch(savingsData.value, updatedSavings => {
      context.emit('updateRegular', updatedSavings)
    })

    return {
      freqOptions,
      savingsData,
      startOptions,
      addRow,
      removeRow
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.p-inputnumber-buttons-stacked .p-inputtext.p-inputnumber-input) {
  border-right: unset !important;
}
:deep(.p-inputtext){
  width: 100%;
  font-size: 18px;
  &.blue-label {
    color: var(--clarity-blue);
    font-weight: bold;
    padding-left: 20px;
  }
}
:deep(.p-inputwrapper) {
  width: 100%;
}
.pad-top {
  padding-top: 5px;
}

.mobile-header {
  width: 100%;
  height: 54px;
  background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
  border-radius: 27px 27px 0px 0px;
  opacity: 1;
}
.savings-table :deep(.p-datatable-header) {
  padding: 0px;
}
.mobile-header {
  display: none;
}

:deep(.p-column-title) {
  display: none;
}

@media only screen and (max-width: 960px)  {
  .mobile-header {
    display: block;
    width: 100%;
    height: 54px;
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, var(--clarity-blue) 100%) 0% 0% no-repeat padding-box;
    border-radius: 27px 27px 0px 0px;
    opacity: 1;
  }
  .savings-table :deep(.p-datatable-header) {
    padding: 0px;
  }
  .mobile-header-label {
    color: var(--clarity-pure-white); margin-left: 30px;
  }
  .mobile-header-label.start {
    width: 40%;
    margin-left: 30px;
  }
  .mobile-header-label.end {
    width: 60%;
    margin-right: 30px;
  }
  .savings-table :deep(.p-column-title) {
    color: var(--clarity-blue);
    text-align: left;
    font: normal normal bold 16px/24px Open Sans;
    letter-spacing: 0px;
  }
  :deep(.p-inputtext) {
    width: 60%;
  }
  :deep(.p-inputwrapper) {
    width: 60%;
  }

}

</style>
