<template>
  <div class="savings" :class="getState">
    <div class="bold title">Projected balance at the current level of savings:</div>
    <h2 :class="[isNegative ? 'red' : 'blue']"><currency-value :value="savings" :decimal-places="2" /></h2>
  </div>
</template>

<script>
import {computed} from "vue";
import CurrencyValue from "@/components/common/CurrencyValue";

export default {
  name: "CurrentSavingsLevel",
  props: ['savings'],
  components: {
    CurrencyValue
  },
  setup(props) {
    const getState = computed(() => {
      switch (true) {
        case props.savings >= 0:
          return 'clarity-gradient-sky-blue'
        case props.savings < 0:
          return 'clarity-gradient-red'
        default:
          return ''
      }
    })

    const isNegative = computed(() => {
      return props.savings < 0
    });

    return {
      getState,
      isNegative
    }
  }
}
</script>

<style scoped lang="scss">
.savings {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 160px;
  border-radius: 27px;

  .title {
    font-size: 18px;
    color: var(--clarity-dark-gery);
    padding: 15px 0px;
  }
  .small-text {
    font-size: 14px;
    color: var(--clarity-grey);
    padding-bottom: 15px;
  }
}
</style>
