<template>
  <div>
    <h3>Results: School Fees Projection</h3>
    <div ref="chartdiv" id="chartdiv" class="education-projection-chart">

    </div>
  </div>
</template>

<script>

import {onMounted, ref, toRef, watch} from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default {
  name: "EducationFeeProjectionChart",
  props: ['feesData', 'barWidth'],
  setup(props) {
    const feesData = toRef(props, 'feesData')
    const keys = ref([])
    const barWidth = toRef(props, 'barWidth')
    const chartRendered = ref(false);
    const series = ref([])
    const xAxis = ref([]);

    watch(feesData, (value) => {
      if(chartRendered.value){
        updateChart(series, value)
      }else{
        renderChart(series, value)
        chartRendered.value = true;
      }
    })

    if (props.feesData) {
      props.feesData.forEach(entry => {
        keys.value.push({
          label: entry.year,
          value: entry.year
        })
      })
    }

    onMounted(() => {
      if (keys.value) {
        renderChart(series, feesData.value)
        chartRendered.value = true;
      }
    })

    const renderChart = (series, data) => {

      am5.addLicense("AM5C334338446");

      let root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);

      root.numberFormatter.setAll({
        numberFormat: "£#,###",
      });

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
      }));

      chart.get("colors").set("colors", [
        am5.color("#179BD7"), // blue - capital
        am5.color("#F18903") // orange - fees
      ]);

      const linearGradient = am5.LinearGradient.new(root, {
        stops: [{
          opacity: 1
        }, {
          opacity: 0.2
        }],
        rotation: 90
      })

      let dotGradient = am5.LinearGradient.new(root, {
        stops: [{
          color: am5.color("#179BD7"),
        }, {
          color: am5.color("#0058A9")
        }]
      });

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      xAxis.value = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
        }),
        startLocation: -0.5,
        endLocation: 0.5,
        centerX: 52,
      }));

      xAxis.value.data.setAll(data);

      var yRenderer = am5xy.AxisRendererY.new(root, {})

      var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        //spacing at the top of the chart
        extraMax: 0.1,
        // spacing at the bottom of the chart
        // extraMin: 0.1,
        renderer: yRenderer
      }));


      // capital series
      series.value['capital'] = chart.series.push(
          am5xy.SmoothedXLineSeries.new(root, {
            name: "Capital",
            xAxis: xAxis.value,
            yAxis: yAxis,
            valueYField: "capitalstart",
            categoryXField: "year",
            locationX: 0,
            tooltip: am5.Tooltip.new(root, {
              keepTargetHover: true,
              labelText: "{categoryX}: {valueY}"
            })
          })
      );

      series.value['capital'].strokes.template.setAll({
        strokeWidth: 3,
        templateField: "strokeSettings"
      });

      series.value['capital'].data.setAll(data);

      series.value['capital'].bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          locationX: 0,
          sprite: am5.Circle.new(root, {
            radius: 9,
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 3,
            fillGradient: dotGradient,

          }),
        });
      });

      series.value['capital'].fills.template.setAll({
        visible: true,
        fillGradient: am5.LinearGradient.new(root, {
          stops: [{
            color: am5.color("#179BD7"),
            opacity: 1
          },
            {
              color: am5.color("#7CC2E2"),
              opacity: 0
            }]
        }),
        fillOpacity: 0.7
      });

      series.value['capital'].strokes.template.setAll({
        strokeWidth: 6,
        strokeGradient: am5.LinearGradient.new(root, {
          stops: [{
            color: am5.color("#179BD7")
          }, {
            color: am5.color("#0058A9")
          }]
        })
      });

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.setAll({
        visible: false
      });
      cursor.lineX.setAll({
        visible: false
      });

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      series.value['fees'] = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Fees",
        xAxis: xAxis.value,
        yAxis: yAxis,
        valueYField: "feesdue",
        categoryXField: "year",
        stroke: am5.color(0x095256),
        locationX: 0
      }));

      series.value['fees'].columns.template.setAll({
        tooltipText: "{name} {categoryX}: {valueY}",
        width: am5.percent(95),
        tooltipY: 0,
        cornerRadiusTL: barWidth.value / 2,
        cornerRadiusTR: barWidth.value / 2,
        maxWidth: barWidth.value,
      });

      series.value['fees'].columns.template.set("fillGradient", linearGradient);
      series.value['fees'].columns.template.set("stroke", root.interfaceColors.get("background"));
      series.value['fees'].columns.template.set("strokeWidth", 3);
      series.value['fees'].columns.template.set("strokeOpacity", 1);

      series.value['fees'].data.setAll(data);

      series.value['fees'].bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Label.new(root, {
            // text: "{valueY}",
            centerX: am5.p50,
            dy: -30,
            scale: 0.8,
            // populateText: true
          })
        });
      });

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      var legend = chart.children.push(
          am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
          })
      );

      legend.labels.template.setAll({
        fontSize: 14,
        fontWeight: "400",
        marginLeft: 18,
        centerY: 6,
      });

      legend.markerRectangles.template.setAll({
        rotation: 180,
        cornerRadiusTL: 15,
        cornerRadiusTR: 15,
        cornerRadiusBL: 15,
        cornerRadiusBR: 15,
        width: 25,
        height: 25,
        dx: 24,
        dy: 24,
      });

      legend.data.setAll(chart.series.values);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      series.value['fees'].appear();
    }

    const updateChart = (series, data) => {
      xAxis.value.data.setAll(data);
      series.value['fees'].data.setAll(data);
      series.value['capital'].data.setAll(data);
    }

    return {}
  }
}
</script>

<style scoped lang="scss">
.education-projection-chart {
  position: relative;
  width: 100%;
  height: 450px;
}
</style>
